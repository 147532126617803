angular.module("sq.admin", ["sq.admin.jobs.component", "sq.admin.experiments.component", 'sq.jobs.experiment.upload.service'])
.config(function config($routeProvider) {
  $routeProvider.when("/admin/jobs", {
    template: "<sq-admin-jobs></sq-admin-jobs>",
    title: "Admin - Jobs",
    secureAsync: true,
    resolve : {
      jobs: (jobTracker) => jobTracker.initializeAsync(),
    }
  })
  .when("/admin/experiments", {
    template: "<sq-admin-experiments></sq-admin-experiments>",
    title: "Admin - Experiments",
    secureAsync: true,
  });
});